.content {
  background-color: white;
  border: 1px black;
  border-radius: 20px;
  padding: 20px;
  height: 200px;
  align-self: stretch;
}

@media (max-height: 400px) {
  .content {
    height: 300px;
  }
}

h4 {
  font-weight: 600;
}

.image-content { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  width: 100%;
}

.img-self {
  align-self: center;
}

.center-text {
  text-align: center;
}

.slick-slide {
  margin: 0 10px;
}

.slick-slider {
  height: 200px;
}

.subheadline-text{
  font-size: small;
  line-height: 16px;
}